<template>
    <div>
        <b-card>
            <h5 class="bg-light text-center rounded py-2 mt-3 ">جزئیات سفارش</h5>
            <b-table responsive striped hover v-if="invoice" :fields="orders_fields" :items="invoice.orders">
                <template v-slot:cell(title)="data">
                    <p style="font-size: 13px; width: 400px;">
                        {{data.item.variety ? data.item.variety.fullTitle : ''}}
                    </p>
                </template>
                <template v-slot:cell(error)="data">
                    <span v-if="data.item.error == 'false'">ندارد</span>
                    <span class="text-danger" v-if="data.item.error == 'more'">تعداد تامین شده از تعداد سفارش
                        کاربر بیشتر می باشد</span>
                    <span class="text-danger" v-if="data.item.error == 'less'">تعداد تامین شده از تعداد سفارش
                        کاربر کمتر می باشد</span>
                </template>
                <template v-slot:cell(sellers)="data">
                    <button class="btn btn-info" @click="ProvideList(data.index)">
                        <i class="fa fa-user"></i>
                    </button>
                </template>
            </b-table>
            <div class="alert alert-danger text-right" v-if="invoice && invoice.orders == ''">
                <span class="text-right">موردی یافت نشد ! </span>
            </div>
        </b-card>
        <b-modal id="sellers-modal" title="فروشندگان" hide-footer size="lg">
            <div class="mt-3">

                <h5 class="bg-light text-center rounded py-3 position-relative">
                    فروشندگان
                    <span class="position-absolute" style="left: 5px; top: 15%;">
                        <span v-b-modal.new-seller class="badge badge-success p-2"><i class="fas fa-plus"></i></span>
                    </span>
                </h5>
                <b-table responsive striped hover v-if="invoice" :fields="seller_fields" :items="sellers">

                    <template v-slot:cell(id)="data">
                        {{data.item.seller_id}}
                    </template>
                    <template v-slot:cell(name)="data">
                        {{data.item.seller ? data.item.seller.name + ' ' + data.item.seller.last_name : ''}}
                    </template>
                    <template v-slot:cell(company_name)="data">
                        {{data.item.seller ? data.item.seller.company_name : ''}}
                    </template>
                    <template v-slot:cell(phone_number)="data">
                        {{data.item.seller ? data.item.seller.phone_number : ''}}
                    </template>
                    <template v-slot:cell(status)="data">
                        <div style="width: 200px;">
                            <p v-if="data.item.status == 'init' " class="p-1 bg-secondary text-center small rounded">
                                ثبت شده</p>

                            <p v-else-if="data.item.status == 'sending' " class="p-1 bg-info text-center small rounded">
                                در حال ارسال</p>
                            <p v-else-if="data.item.status == 'unavailable' "
                                class="p-1 bg-primary text-center small rounded">
                                موجود نیست
                            </p>
                            <p v-else-if="data.item.status == 'sent' " class="p-1 bg-success text-center small rounded">
                                ارسال شد
                            </p>
                        </div>
                    </template>
                    <template v-slot:cell(details)="data">
                        <button class="btn-sm btn btn-info" @click="showOrderDetails(data.index)">
                            <i class="fa fa-info"></i>
                        </button>
                    </template>
                </b-table>
            </div>
        </b-modal>
        <b-modal id="order-detail" :title="'اقلام تهیه شده توسط فروشنده : ' + seller.name + ' ' + seller.last_name"
            hide-footer size="xl">
            <form @submit.prevent="orderUpdate" id="order-update">
                <div v-if="orderDetail.orders" class="box-shadow mt-3 rounded pt-1">
                    <b-table responsive striped hover class="mb-0" :fields="order_detail_table_fields"
                        :items="orderDetail.orders">
                        <template v-slot:cell(quantity_send)="data">
                            <div class="d-flex justify-content-start align-items-center">
                                <span class=" cursor-pointer increment p-0 text-center" @click="increment(data.index) ">
                                    <i class="fas fa-plus"></i>
                                </span>
                                <span class="border rounded " style="height: 30px; line-height: 5px;">
                                    {{data.item.pivot ? data.item.pivot.available_count : 0}}
                                </span>
                                <span class=" cursor-pointer increment p-0 text-center" @click="decrement(data.index)">
                                    <i class="fas fa-minus"></i>
                                </span>
                            </div>
                        </template>
                        <template v-slot:cell(price)="data">
                            <b-form-input v-if="data.item.pivot.price != null" :value="data.item.pivot.price | price"
                                @input="value=>{data.item.pivot.price = +value.replace(/\D/g, '')}"></b-form-input>
                        </template>
                        <template v-slot:cell(quantity)="data">
                            {{data.item.quantity}}
                        </template>
                        <template v-slot:cell(wanted_count)="data">
                            <div class="d-flex justify-content-start align-items-center">
                                <span class=" cursor-pointer increment p-0 text-center"
                                    @click="incrementWantedCount(data.index) ">
                                    <i class="fas fa-plus"></i>
                                </span>
                                <span class="border rounded " style="height: 30px; line-height: 5px;">
                                    {{data.item.pivot ? data.item.pivot.wanted_count : 0}}
                                </span>
                                <span class=" cursor-pointer increment p-0 text-center"
                                    @click="decrementWantedCount(data.index)">
                                    <i class="fas fa-minus"></i>
                                </span>
                            </div>
                        </template>
                        <template v-slot:cell(title)="data">
                            {{ data.item.variety ? data.item.variety.fullTitle : ''  }}
                        </template>
                        <template v-slot:cell(index)="data">
                            {{ data.index + 1  }}
                        </template>
                    </b-table>
                </div>
                <div class="d-flex justify-content-between align-items-center mt-5 ">
                    <div class="d-flex justify-content-center">
                        <span class="fontWeightBold mt-2 ml-1">
                            وضعیت کلی فاکتور :
                        </span>
                        <b-form-select v-model="orderDetail.status" style="width: 200px;" name="status">
                            <b-form-select-option value="init">ثبت شده</b-form-select-option>
                            <b-form-select-option value="sending">در حال ارسال</b-form-select-option>
                            <b-form-select-option value="unavailable">موجود نیست</b-form-select-option>
                            <b-form-select-option value="sent">ارسال شد</b-form-select-option>
                        </b-form-select>
                    </div>
                    <div>
                        <button class="btn btn-success" type="submit" :disabled="disabled"> ثبت وضعیت</button>
                    </div>

                </div>
            </form>
        </b-modal>
        <b-modal id="new-seller" hide-footer title="افزودن تامین کننده">
            <form @submit.prevent="makeProvide">
                <b-form-group>
                    <label for="seller"> فروشنده <i class="fas fa-asterisk text-danger"
                            style="font-size: 0.5rem;"></i></label>
                    <multiselect id="seller" class="form-group" :options="sellersAll" label="name" track-by="id"
                        v-model="make_provide.seller_id" placeholder="" :searchable="true" :close-on-select="true"
                        :show-labels="false">
                    </multiselect>
                </b-form-group>
                <b-form-group>
                    <label for="wanted-count"> تعداد درخواستی <i class="fas fa-asterisk text-danger"
                            style="font-size: 0.5rem;"></i></label>
                    <b-form-input v-model="make_provide.wanted_count" id='wanted-count'></b-form-input>
                </b-form-group>
                <div class="text-center">
                    <button :disabled="disabled" type="submit" class="btn btn-success">ثبت</button>
                </div>
            </form>
        </b-modal>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                title: 'جزئیات سفارش',
                invoice: {},
                orders_fields: [{
                        key: 'id',
                        label: 'شناسه'
                    },
                    {
                        key: 'title',
                        label: 'عنوان محصول'
                    },
                    {
                        key: 'quantity',
                        label: 'تعداد سفارش'
                    },
                    {
                        key: 'total_available_in_provides',
                        label: 'تعداد تامین شده'
                    },
                    {
                        key: 'error',
                        label: 'پیام'
                    },
                    {
                        key: 'sellers',
                        label: 'فروشندگان'
                    },
                ],
                seller_fields: [{
                        key: 'id',
                        label: 'شناسه'
                    },
                    {
                        key: 'name',
                        label: 'نام و نام خانوادگی'
                    },
                    {
                        key: 'company_name',
                        label: 'نام شرکت'
                    },
                    {
                        key: 'phone_number',
                        label: 'شماره تماس'
                    },
                    {
                        key: 'status',
                        label: 'وضعیت سفارش'
                    },
                    {
                        key: 'details',
                        label: 'اقلام'
                    },
                ],
                order_detail_table_fields: [{
                        key: 'title',
                        label: 'عنوان'
                    },
                    {
                        key: 'quantity',
                        label: 'تعداد سفارش کاربر'
                    },
                    {
                        key: 'wanted_count',
                        label: 'تعداد درخواست'
                    },
                    {
                        key: 'quantity_send',
                        label: 'تعداد ارسال'
                    },
                    {
                        key: 'price',
                        label: 'قیمت'
                    },
                ],
                disabled: false,
                sellers: null,
                orderDetail: {},
                seller: {
                    name: '',
                    last_name: ''
                },
                make_provide: {
                    seller_id: null,
                    wanted_count: 0
                },
                sellersAll: [],
                orderId: '',
                oldStatus: null
            }
        },
        watch: {
            'orderDetail.status': function (newVal, oldVal) {
                this.oldStatus = oldVal
                if(!oldVal) return
                if (newVal == 'sent') {
                    window.swal({
                            // title: "آیتم حذف شود؟",
                            text: "آیا از تغییر وضعیت به ارسال شد اطمینان دارید ؟",
                            icon: "warning",

                            showCancelButton: true,
                            showConfirmButton: true,
                            dangerMode: true,
                            buttons: ["خیر", "بله تغییر کند"]
                        })
                        .then((willDelete) => {
                            if(willDelete == null) {
                               this.orderDetail.status = this.oldStatus
                            }
                        })
                        .catch(() => {

                        })
                }

            }
        },
        mounted() {
            this.$route.params.id ? this.showInvoiceDetails(this.$route.params.id) : ''
            this.loadSellers()
        },
        methods: {
            loadSellers() {
                this.$axios.get(this.$root.baseUrl + '/api/admin/sellers?all=true')
                    .then(response => {
                        this.sellersAll = response.data.data
                        this.sellersAll.map(item => item.name = item.name + ' ' + item.last_name +
                            ' - نام شرکت : ' +
                            item
                            .company_name)
                    })
            },
            showInvoiceDetails(id) {
                this.show = true
                this.$axios.get(this.$root.baseUrl + `/api/admin/orders/${id}`)
                    .then(response => {
                        this.invoice = response.data.data.invoice
                        this.show = false
                    })

            },
            ProvideList(index) {
                let item = window.clone(this.invoice.orders[index].provides)
                this.orderId = this.invoice.orders[index].id
                item.index = index
                this.sellers = item
                this.$root.$emit('bv::show::modal', 'sellers-modal')
            },
            showOrderDetails(index) {
                let item = window.clone(this.sellers[index])
                item.index = index
                this.orderDetail = item
                this.seller.name = this.orderDetail.seller.name
                this.seller.last_name = this.orderDetail.seller.last_name
                this.$root.$emit('bv::show::modal', 'order-detail')
            },
            increment(index) {
                if (this.orderDetail.orders[index].pivot.available_count < this.orderDetail.orders[index].pivot
                    .wanted_count) {
                    this.orderDetail.orders[index].pivot.available_count = this.orderDetail.orders[index].pivot
                        .available_count +
                        1
                }
            },
            decrement(index) {
                if (this.orderDetail.orders[index].pivot.available_count > 0) {
                    this.orderDetail.orders[index].pivot.available_count = this.orderDetail.orders[index].pivot
                        .available_count -
                        1
                }
            },
            incrementWantedCount(index) {
                this.orderDetail.orders[index].pivot.wanted_count = this.orderDetail.orders[index].pivot.wanted_count +
                    1
            },
            decrementWantedCount(index) {
                if (this.orderDetail.orders[index].pivot.wanted_count > 0) {
                    this.orderDetail.orders[index].pivot.wanted_count = this.orderDetail.orders[index].pivot
                        .wanted_count - 1
                }
            },
            orderUpdate() {
                this.disabled = true
                let form = document.getElementById('order-update')
                let formData = new FormData(form)
                this.orderDetail.orders.map((el, index) => {
                    formData.append('orders[' + index + `][id]`, el.id)
                    formData.append('orders[' + index + `][available_count]`, el.pivot.available_count)
                    formData.append('orders[' + index + `][wanted_count]`, el.pivot.wanted_count)
                    formData.append('orders[' + index + `][price]`, el.pivot.price)
                })
                this.$axios.post(this.$root.baseUrl +
                        `/api/admin/sellers/${this.orderDetail.seller_id}/provides/${this.orderDetail.id}`, formData)
                    .then((response) => {
                        this.$root.success_notification(response.data.message)
                        this.sellers[this.orderDetail.index].status = this.orderDetail.status
                        this.sellers[this.orderDetail.index].orders = response.data.data.orders
                        this.invoice.orders[this.sellers.index].provides[this.orderDetail.index].orders = response
                            .data.data.orders
                    })
                    .catch((error) => {
                        this.$root.error_notification(error)
                    })
                    .finally(() => {
                        this.disabled = false
                    })
            },
            makeProvide() {
                this.disabled = true
                this.$axios.post(`/api/admin/orders/${this.orderId}/make-provide`, {
                        seller_id: this.make_provide.seller_id != null ? this.make_provide.seller_id.id : '',
                        wanted_count: this.make_provide.wanted_count
                    })
                    .then(response => {
                        this.$root.success_notification(response.data.message)
                        this.sellers = response.data.data.invoice.orders.provides
                        this.invoice.orders = response.data.data.invoice.orders
                        this.$root.$emit('bv::hide::modal', 'sellers-modal')
                        this.$root.$emit('bv::hide::modal', 'new-seller')
                    })
                    .catch(error => {
                        this.$root.error_notification(error)
                    })
                    .finally(() => {
                        this.disabled = false
                    })
            }
        }
    }
</script>
<style scoped>
    .box-shadow {
        box-shadow: 5px 9px 25px 6px #0a0a0a21;
        overflow: hidden;
    }

    .cursor-pointer {
        cursor: pointer !important;
    }

    .increment {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }

    .increment .fas {
        font-size: 11px;
    }
</style>